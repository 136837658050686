<template>
  <LoadingPage v-if="!imagesLoaded" :text="translate('LoadingText')"></LoadingPage>
  <div v-else>
    <div class="About">
      <div class="About-top">
        <div class="About-top-content">
          <div class="About-top-content-background">
            <img loading="eager" src="@/assets/pictures/about2.jpg" alt="background" />
          </div>
          <div class="About-top-content-title" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="150">{{ translate("HelloTitle") }}</div>
          <div class="About-top-content-text" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="250">{{ translate("AboutTopText") }}</div>
          <div class="About-top-content-scroll-text" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="350">
            {{ translate("ScrollText") }}
            <span class="emoji">&#x1F604;</span>
          </div>
        </div>
      </div>
      <div class="About-skills">
        <div class="About-skills-box">
          <div class="About-skills-box-title" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">{{ translate("TitleData") }}</div>
          <div class="About-skills-box-text" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">
            <strong>{{ translate("TextPython") }}</strong> &mdash; 
            <strong>SQL</strong> &mdash;
            <strong>Machine Learning </strong> &mdash;
            <strong>Deep Learning</strong>
          </div>
        </div>
        <div class="About-skills-box">
          <div class="About-skills-box-title" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">{{ translate("TitleTool") }}</div>
          <div class="About-skills-box-tools" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">
            <div class="About-skills-box-tool">
              <img src="../assets/logos/Github.png" alt="Github" />
              <strong>Github</strong>
            </div>
            <div class="About-skills-box-tool">
              <img src="../assets/logos/Visual_Studio_Code.png" alt="VS Code" />
              <strong>VS Code</strong>
            </div>
            <div class="About-skills-box-tool">
              <img src="../assets/logos/Anaconda.png" alt="Anaconda" />
              <strong>Anaconda</strong>
            </div>
            <div class="About-skills-box-tool">
              <img src="../assets/logos/Power_BI.png" alt="Power BI" />
              <strong>Power BI</strong>
            </div>
            <div class="About-skills-box-tool">
              <img src="../assets/logos/excel.png" alt="Excel" />
              <strong>Excel</strong>
            </div>
            <div class="About-skills-box-tool">
              <img src="../assets/logos/Azure.png" alt="Azure" />
              <strong>Azure</strong>
            </div>
          </div>
        </div>
        <div class="About-skills-box">
          <div class="About-skills-box-title" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">{{ translate("TitleWeb") }}</div>
          <div class="About-skills-box-text" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">
            <strong>HTML</strong> &mdash; 
            <strong>CSS</strong> &mdash; 
            <strong>JavaScript</strong> &mdash;
            <strong>Vue.js</strong>
          </div>
        </div>
        
        <!-- <div class="About-skills-box" id="Learning">
          <div class="About-skills-box-title" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">{{ translate("TitleLearning") }}</div>
          <div class="About-skills-box-text" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">
            <strong></strong> &mdash;
          </div>
        </div> -->
      </div>
      <div class="About-school">
        <div class="About-school-title" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">{{ translate("FormationTitle") }}</div>
        <div class="About-school-container">
            <div class="About-school-container-text" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">
              <a href="https://www.cesi.fr">CESI Ecole d'ingénieurs</a>
              <div class="About-school-container-text-date">2020 &mdash; 2025</div>
              <div class="About-school-container-text-text">{{ translate("FormationText1") }}</div>
              <div class="About-school-container-text-list">
                <ul>
                  <li>{{ translate("FormationText2") }}</li>
                  <li>{{ translate("FormationText3") }}</li>
                  <li>{{ translate("FormationText4") }}</li>
                </ul>
              </div>
            </div>
        </div>
      </div>
      <div class="About-hobbies">
        <div class="About-hobbies-title" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">{{ translate("HobbiesTitle") }}</div>
        <div class="About-hobbies-container">
          <div class="About-hobbies-box" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">
            <div class="About-hobbies-box-background" id="Trip">
              <img loading="eager" src="@/assets/pictures/flight.gif" alt="background" />
            </div>
            <div class="About-hobbies-box-title">{{ translate("Trip") }}</div>
            <div class="About-hobbies-box-text">{{ translate("TripText") }}</div>
          </div>
          <div class="About-hobbies-box" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">
            <div class="About-hobbies-box-background" id="Movie">
              <img loading="eager" src="@/assets/pictures/starwars.gif" alt="background" />
            </div>
            <div class="About-hobbies-box-title">{{ translate("Movie") }}</div>
            <div class="About-hobbies-box-text">{{ translate("MovieText") }}</div>
          </div>
          <div class="About-hobbies-box" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">
            <div class="About-hobbies-box-background" id="Sport">
              <img loading="eager" src="@/assets/pictures/muscu.gif" alt="background" />
            </div>
            <div class="About-hobbies-box-title">{{ translate("Sport") }}</div>
            <div class="About-hobbies-box-text">{{ translate("SportText") }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import en from '../components/en'
import fr from '../components/fr'
import Footer from '@/components/Footer.vue'
import LoadingPage from '@/components/LoadingPage.vue'
import ImageLoaderMixin from '@/mixins/ImageLoaderMixin.js'

export default {
  name: 'AboutPage',

  components: {
    Footer,
    LoadingPage
  },

  props: {
    english: Boolean,
  },

  mixins:[en, fr, ImageLoaderMixin],

  mounted() {
    const images = [
      require("@/assets/pictures/about2.jpg"),
      require("@/assets/pictures/trip.jpg"),
      require("@/assets/pictures/starwars.gif"),
      require("@/assets/pictures/sport.jpg"),
      require("@/assets/logos/Github.png"),
      require("@/assets/logos/Visual_Studio_Code.png"),
      require("@/assets/logos/Anaconda.png"),
      require("@/assets/logos/Power_BI.png"),
      require("@/assets/logos/excel.png"),
      require("@/assets/logos/Azure.png")
    ]
    this.loadImages(images)
  },

  methods: {
    translate(prop) {
      if(!this.english) {
        return this["fr"][prop]
      } else {
        return this["en"][prop]
      }
    },
  }
}
</script>

<style lang="scss">
@import url('../style/reset.css');

.About{
  width: 100%;

  .About-top{
    width: 100%;
    height: 100vh;
    min-width: 300px;
    min-height: 500px;
    color: var(--theme-first-color);
    background-color: var(--theme-background-color);
    display: flex;
    justify-content: center;
    transition: background-color 0.8s;

    .About-top-content{
      margin-top: 10vh;
      width: 80%;
      height: 80vh;

      .About-top-content-background{
        width: 80%;
        height: 80%;
        min-width: 250px;
        min-height: 400px;        
        position: absolute;
        opacity: 0.35;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
          width: 100%;
          height: 100%;
          min-width: 250px;
          min-height: 400px;
          object-fit: cover;
        }
      }

      .About-top-content-title{
        padding-top: 5vh;
        padding-left: 5%;
        padding-right: 5%;
        width: 90%;
        position: relative;
        white-space: pre-line;
        font-family: 'Monoton', cursive;
        font-size: 100px;
        word-spacing: 15px;
        line-height: 1.5;
      }

      .About-top-content-text, .About-top-content-scroll-text{
        padding-top: 5vh;
        padding-left: 5%;
        padding-right: 5%;
        width: 90%;
        position: relative;
        white-space: pre-line;
        font-size: 25px;
        line-height: 1.5;
      }
    }
  }

  .About-skills{
    width: 100%;
    min-width: 300px;
    height: fit-content;
    color: var(--theme-first-color);
    background-color: var(--theme-background-color);
    transition: background-color 0.8s;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vh;

    .About-skills-box{
      padding-bottom: 10vh;
      padding-left: 10%;
      padding-right: 10%;
      width: 80%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;

      .About-skills-box-title{
        padding-bottom: 4vh;
        font-size: 22px;
        color: var(--theme-second-color);
        font-weight: bold;
      }

      .About-skills-box-text{
        font-size: 25px;
        text-align: center;
      }

      .About-skills-box-tools{
        font-size: 25px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 90%;
        height: fit-content;

        .About-skills-box-tool{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 200px;
          width: 200px;
          margin-top: 2.5vh;
          margin-left: 2.5vh;
          margin-right: 2.5vh;

          img{
            width: 150px;
            height: 150px;
            margin-bottom: 2vh;
          }

        }
      }
    }

    #Learning{
      color: rgba(109, 199, 212);
    }
  }

  .About-school{
    width: 100%;
    min-width: 300px;
    height: fit-content;
    color: var(--theme-first-color);
    background-color: var(--theme-background-color);
    transition: background-color 0.8s;
    display: flex;
    flex-direction: column;

    .About-school-title{
      padding-left: 10%;
      padding-right: 10%;
      width: 80%;
      font-size: 55px;
    }

    .About-school-container{
      width: 80%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 7vh;
      padding-bottom: 10vh;

      .About-school-container-text{
        width: 100%;
        height: fit-content;

        a{
          font-size: 28px;
          color: var(--theme-first-color);
          transition: color 0.2s ease;
          font-weight: bold;
        }

        a:hover{
          color: var(--theme-second-color);
        }

        .About-school-container-text-date{
          padding-top: 2vh;
          font-style: italic;
          font-size: 20px;
        }

        .About-school-container-text-text{
          height: fit-content;
          font-size: 20px;
          padding-top: 4vh;
          padding-bottom: 2vh;
        }

        .About-school-container-text-list{
          height: fit-content;
          line-height: 2;
          font-size: 20px;
        }
      }
    }
  }

  .About-hobbies{
    width: 100%;
    min-width: 300px;
    height: fit-content;
    color: var(--theme-first-color);
    background-color: var(--theme-background-color);
    transition: background-color 0.8s;

    .About-hobbies-title{
      padding-left: 10%;
      padding-right: 10%;
      width: 80%;
      font-size: 55px;
    }

    .About-hobbies-container{
      width: 100%;
      height: fit-content;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding-bottom: 7vh;

      .About-hobbies-box{
        width: 600px;
        height: 70vh;
        min-height: 350px;
        border-radius: 15px;
        margin-top: 10vh;
        margin-left: 5%;
        margin-right: 5%;

        .About-hobbies-box-background{
          border-radius: 15px;
          width: inherit;
          min-height: 350px;
          height: inherit;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;

          img{
            width: inherit;
            height: inherit;
            min-height: 350px;
            border-radius: 15px;
            opacity: 0.3;
            object-fit: cover;
          }
        }

        .About-hobbies-box-title{
          width: fit-content;
          height: fit-content;
          position: relative;
          padding-top: 5vh;
          padding-left: 2vh;
          font-size: 35px;
          font-weight: bold;
        }

        .About-hobbies-box-text{
          width: fit-content;
          height: fit-content;
          position: relative;
          padding-top: 5vh;
          padding-left: 2vh;
          padding-right: 2vh;
          font-size: 20px;
          white-space: pre-line;
        }
      }
    }
  }
}

/*========================== RESPONSIVE ==========================*/
@media screen and (max-width: 1100px){

  .About{

    .About-top{

      .About-top-content{

        .About-top-content-title{
          font-size: 65px;
        }

        .About-top-content-text, .About-top-content-scroll-text{
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 650px), (max-height: 600px){

  .About{

    .About-top{

      .About-top-content{

        .About-top-content-title{
          font-size: 40px;
        }

        .About-top-content-text, .About-top-content-scroll-text{
          font-size: 15px;
        }
      }
    }

    .About-skills{

      .About-skills-box{l

        .About-skills-box-title{
          font-size: 13px;
        }

        .About-skills-box-text{
          font-size: 15px;
        }

        .About-skills-box-tools{

          .About-skills-box-tool{
            height: 150px;
            width: 150px;

            img{
              width: 100px;
              height: 100px;
            }
          }
        }
      }
    }

    .About-school{

      .About-school-title{
        font-size: 25px;
      }

      .About-school-container{

        .About-school-container-text{

          a{
            font-size: 20px;
          }

          .About-school-container-text-date{
            font-size: 10px;
          }

          .About-school-container-text-text{
            font-size: 15px;
          }

          .About-school-container-text-list{
            font-size: 15px;
          }
        }
      }
    }

    .About-hobbies{

      .About-hobbies-title{
        font-size: 25px;
      }

      .About-hobbies-container{

        .About-hobbies-box{
          width: 100%;

          .About-hobbies-box-title{
            font-size: 20px;

            .About-hobbies-title{
              font-size: 20px;
            }
          }

          .About-hobbies-box-text{
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px){

  .About{

    .About-top{

      .About-top-content{
        width: 90%;

        .About-top-content-background{
          width: 90%;
        }

        .About-top-content-title{
          font-size: 37px;
          padding-top: 10vh;
        }

        .About-top-content-text, .About-top-content-scroll-text{
          font-size: 15px;
        }
      }
    }

    .About-skills{
      padding-top: 1vh;

      .About-skills-box{
        padding-bottom: 7vh;

        .About-skills-box-title{
          font-size: 10px;
        }

        .About-skills-box-text{
          font-size: 12px;
        }

        .About-skills-box-tools{
          font-size: 12px;

          .About-skills-box-tool{
            height: 80px;
            width: 80px;

            img{
              width: 60px;
              height: 60px;
            }
          }
        }
      }
    }

    .About-school{

      .About-school-title{
        font-size: 25px;
        padding-top: 2vh;
      }

      .About-school-container{
        padding-bottom: 7vh;
        padding-top: 2vh;

        .About-school-container-text{
          padding-bottom: 0;
          
          .About-school-container-text-date{
            font-size: 12px;
          }

          .About-school-container-text-text{
            font-size: 15px;
          }

          .About-school-container-text-list{
            font-size: 15px;
          }
        }
      }
    }

    .About-hobbies{

      .About-hobbies-title{
        font-size: 25px;
      }

      .About-hobbies-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .About-hobbies-box{
          width: 90%;
          height: 60vh;
          margin-top: 5vh;

          .About-hobbies-box-background{
            width: 100%;
            height: 100%;
            min-height: 250px;

            img{
              width: 100%;
              height: 100%;
              min-height: 250px;
            }
          }

          .About-hobbies-box-text{
              padding-top: 2vh;
              font-size: 15px;
            }
        }
      }
    }
  }
}
</style>