<template>
  <LoadingPage v-if="!imagesLoaded" :text="translate('LoadingText')"></LoadingPage>
  <div v-else>
    <div class="VideoProject">
      <div class="VideoProject-background"></div>
      <div class="VideoProject-content">
        <div class="VideoProject-content-date" v-motion-slide-top>2025</div>
        <div class="VideoProject-content-title" v-motion-slide-top>{{ translate("YoutubeVideoBox") }}</div>
        <div class="VideoProject-content-text" v-motion-slide-top>{{ translate("YoutubeVideoText") }}</div>
        <div class="Video-container">
          <iframe 
            src="https://www.youtube.com/embed/7GXkPh6AyMU?si=0w79JEo4mC8vpaEx"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import en from '@/components/en'
import fr from '@/components/fr'
import Footer from '../components/Footer'
import LoadingPage from '@/components/LoadingPage.vue'
import ImageLoaderMixin from '@/mixins/ImageLoaderMixin.js'
import twemoji from 'twemoji'

export default {

  data() {
    return {
    }
  },

  components: {
    Footer,
    LoadingPage
  },

  props: {
    english: Boolean,
  },
    
  mixins:[en, fr, ImageLoaderMixin],

  mounted() {
    twemoji.parse(this.$el)
    const images = [
      require("@/assets/pictures/deepfake.png"),
    ]
    this.loadImages(images)
  },

  methods: {
    translate(prop) {
      if(!this.english) {
        return this["fr"][prop]
      } else {
        return this["en"][prop]
      }
    },
  }
}
</script>

<style lang="scss">
@import url('@/style/reset.css');

.VideoProject{
  width: 100%;
  height: 100vh;
  min-width: 300px;
  min-height: 500px;
  color: var(--theme-first-color);
  background-color: var(--theme-background-color);
  transition: background-color 0.8s;
  display: flex;
  justify-content: center;
  align-items: center;

  .VideoProject-background{
    width: 80%;
    height: 80%;
    min-height: 400px;
    background-image: url(@/assets/pictures/deepfake.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    opacity: 0.2;
  }

  .VideoProject-content{
    position: absolute;
    width: 70%;
    height: 70%;
    min-height: 300px;

    .VideoProject-content-date{
      font-size: 14px;
      font-style: italic;
    }

    .VideoProject-content-title{
      font-weight: bold;
      padding-top: 10vh;
      font-size: 35px;
    }

    .VideoProject-content-text{
      font-size: 20px;
      padding-top: 5vh;
      white-space: pre-line;
      line-height: 1.5;
      text-align: justify;

      .emoji{
        width: 20px;
        height: 20px;
      }
    }

    .Video-container{
      margin-top: 2vh;
      display: grid;
      place-items: center;
      width: 100%;
      overflow: hidden;
      height: 35vh;

      iframe{
        width: 45%;
        height: 35vh;
        border: none;
      }
    }
  }
}

/*========================== RESPONSIVE ==========================*/
@media screen and (max-height: 800px){
  .VideoProject{

    .VideoProject-content{

      .VideoProject-content-title{
        padding-top: 4vh;
        font-size: 30px;
      }

      .VideoProject-content-text{
        font-size: 15px;
        padding-top: 4vh;

        .emoji{
          width: 15px;
          height: 15px;
        }
      }

      .Video-container{
        margin-top: 1vh;
        height: 25vh;

        iframe{
          width: 100%;
          height: 25vh;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px){
  .VideoProject{

    .VideoProject-content{

      .Video-container{
        height: 25vh;
        
        iframe{
          width: 60%;
          height: 25vh;
        }
      }
    }
  }
}

@media screen and (max-width: 800px){
  .VideoProject{

    .VideoProject-content{

      .VideoProject-content-date{
        font-size: 12px;
      }

      .VideoProject-content-title{
        padding-top: 2vh;
        font-size: 22px;
      }

      .VideoProject-content-text{
        font-size: 12px;
        padding-top: 2vh;

        .emoji{
          width: 12px;
          height: 12px;
        }
      }

      .Video-container{
        margin-top: 1vh;
        height: 25vh;
        
        iframe{
          width: 100%;
          height: 25vh;
        }
      }
    }
  }
}

@media screen and (max-width: 370px){
  .VideoProject{

    .VideoProject-content{

      .VideoProject-content-date{
        font-size: 10px;
      }

      .VideoProject-content-title{
        padding-top: 2vh;
        font-size: 20px;
      }

      .VideoProject-content-text{
        font-size: 10px;
        padding-top: 2vh;

        .emoji{
          width: 10px;
          height: 10px;
        }
      }

      .Video-container{
        margin-top: 1vh;
      }
    }
  }
}
</style>