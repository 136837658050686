<template>
    <LoadingPage v-if="!imagesLoaded" :text="translate('LoadingText')"></LoadingPage>
    <div v-else>
        <div class="HomePage">
            <div class="HomePage-background">
                <div class="HomePage-background-picture">
                    <img loading="eager" src="../assets/pictures/portrait.png" alt="portrait" :style="{ opacity: opacityValue }" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="300">
                </div>
            </div>
            <div class="HomePage-mouse-loader" :style="{ opacity: opacityValue }">
                <span class="loader"></span>
            </div>
            <div class="HomePage-intro">
                <div class="HomePage-intro-title">
                    <div class="HomePage-intro-title-surname" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="100">VICTOR</div>
                    <div class="HomePage-intro-title-name" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="200">BILLAT</div>
                    <div class="HomePage-intro-title-icon">
                        <a id="Linkedin" href="https://linkedin.com/in/victor-billat" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="300"><i class="uil uil-linkedin"></i></a>
                        <a id="Github" href="https://github.com/Vic06163" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="300"><i class="uil uil-github"></i></a>
                        <!-- <a id="Kaggle" href="https://www.kaggle.com/victorbillat" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="300">kaggle</a> -->
                    </div>
                </div>
                <div class="HomePage-intro-text" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="400" >{{ translate("TextHomePage") }}</div>
            </div>
        </div>
        <div class="ProjectPage">
            <div class="ProjectPage-internship">
                <div class="ProjectPage-internship-text" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">{{ translate("MyInternshipText") }}</div>
                <div class="ProjectPage-internship-box">
                    <InternshipBox class="Internship-box" title="Enedis" :date="translate('DateEnedis')" :country="translate('LocationEnedis')" :text="translate('TextEnedis')" backgroundImage="https://pbs.twimg.com/media/FMSsiWnWQAkkdP-.jpg:large" link="/enedis-internship" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100"></InternshipBox>
                    <div class="ProjectPage-internship-box-blank"></div>
                    <InternshipBox class="Internship-box" title="VINCI Construction" :date="translate('DateVinci')" :country="translate('LocationVinci')" :text="translate('TextVinci')" backgroundImage="https://www.vinci-concessions.com/uploads/2019/05/regina_visuel-intro-1920x1106.jpg" link="/vinci-internship"  v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100"></InternshipBox>
                </div>
                <div class="ProjectPage-internship-box">
                    <InternshipBox class="Internship-box" title="Leadix" :date="translate('DateLeadix')" :country="translate('LocationLeadix')" :text="translate('TextLeadix')" backgroundImage="https://www.visit.brussels/content/dam/visitbrussels/images/b2c/what-to-do/les-incontournables-de-bruxelles/GRAND_PLACE_4212-%C2%A9_visit.brussels_-_Jean-Michel_Byl.jpg/jcr:content/renditions/large-1920px.jpeg" link="/leadix-internship" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100"></InternshipBox>
                    <div class="ProjectPage-internship-box-blank"></div>
                    <ExperienceOnGoing class="Experience-box" title="Stellantis" :date="translate('DateStellantis')" :country="translate('LocationStellantis')" :text="translate('TextStellantis')" backgroundImage="https://www.stellantis.com/content/dam/stellantis-corporate/brands/peugeot/New-Peugeot-E-3008.jpg" :onGoingText="translate('OnGoingText')" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100"></ExperienceOnGoing>
                </div>
            </div>
            <div class="ProjectPage-personal">
                <div class="ProjectPage-personal-text" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">{{ translate("MyPersonalText") }}</div>
                <div class="ProjectPage-personal-box" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">
                    <PersonalBox class="Personal-box" date="2023" :title="translate('PortfolioTitleBox')" backgroundImage="https://www.synolia.com/wp-content/uploads/2019/12/header-vue-js.jpg" link="/portfolio-project"></PersonalBox>
                    <PersonalBox class="Personal-box" date="2025" :title="translate('YoutubeVideoBox')" backgroundImage="https://bigmedia.bpifrance.fr/sites/default/files/styles/bigmedia_article/public/2022-04/Deepfake%20ethique.jpg?itok=MphB-ap8" link="/deepfake-video"></PersonalBox>               
                </div>
            </div>
            <div class="ProjectPage-contact">
                <div class="ProjectPage-contact-text" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="100">
                    <a href="/contact">
                        {{ translate("ContactText") }}
                        <i class="fa-solid fa-arrow-right"></i>
                    </a>
                </div>
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import en from '../components/en'
import fr from '../components/fr'
import InternshipBox from '../components/InternshipBox'
import ExperienceOnGoing from '../components/ExperienceOnGoing'
import PersonalBox from '../components/PersonalBox'
import Footer from '../components/Footer'
import '@fortawesome/fontawesome-free/css/all.css'
import LoadingPage from '@/components/LoadingPage.vue'
import ImageLoaderMixin from '@/mixins/ImageLoaderMixin.js'

export default {
  components: { InternshipBox, PersonalBox, ExperienceOnGoing, Footer, LoadingPage },

    data() {
        return {
            opacityValue: 1,
        }
    },

    props: ['english'],

    mixins:[en, fr, ImageLoaderMixin],

    mounted() {
        window.addEventListener('scroll', this.handleScroll)
        const images = [
            require('../assets/pictures/portrait.png'),
            'https://pbs.twimg.com/media/FMSsiWnWQAkkdP-.jpg:large',
            'https://www.vinci-concessions.com/uploads/2019/05/regina_visuel-intro-1920x1106.jpg',
            'https://www.visit.brussels/content/dam/visitbrussels/images/b2c/what-to-do/les-incontournables-de-bruxelles/GRAND_PLACE_4212-%C2%A9_visit.brussels_-_Jean-Michel_Byl.jpg/jcr:content/renditions/large-1920px.jpeg',
            'https://www.synolia.com/wp-content/uploads/2019/12/header-vue-js.jpg',
            'https://bigmedia.bpifrance.fr/sites/default/files/styles/bigmedia_article/public/2022-04/Deepfake%20ethique.jpg?itok=MphB-ap8',
            'https://www.stellantis.com/content/dam/stellantis-corporate/brands/peugeot/New-Peugeot-E-3008.jpg'
        ]
        this.loadImages(images)
    },

    methods: {
        handleScroll() {
            this.opacityValue = 1 - window.scrollY / (screen.height * 0.7)
        },

        translate(prop) {
            if(!this.english) {
                return this["fr"][prop]
            } else {
                return this["en"][prop]
            }
        }
    },
}
</script>

<style lang="scss">
@import url('../style/reset.css');

.HomePage{
    width: 100%;
    height: 100vh;
    min-width: 300px;
    min-height: 400px;
    color: var(--theme-first-color);
    background-color: var(--theme-background-color);
    transition: background-color 0.8s;

    .HomePage-background{
        position: absolute;
        width: 100%;
        height: 100vh;
        min-width: 300px;
        min-height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: var(--theme-opacity-home-background);

        .HomePage-background-picture{
            height: 90vh;
            width: 90%;
            min-width: 300px;
            min-height: 400px;
            margin-top: 10vh;
            margin-left: 35%;
            overflow: hidden;

            img{
                width: auto;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .HomePage-mouse-loader{
        position: absolute;
        width: 100%;
        height: 98vh;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 1;

        .loader {
            width: 32px;
            height: 58px;
            box-sizing: border-box;
            border: 2px solid var(--theme-first-color);
            position: relative;
            border-radius: 50% 50% 50% 50% / 25% 25% 25% 25%;
            opacity: 0.6;
            }

            .loader::before {
                content: '';
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50% , 50%);
                width: 7px;
                height: 7px;
                background: var(--theme-first-color);
                border-radius: 50%;
                animation: fadeDown 1s ease-out infinite;
            }

            @keyframes fadeDown {
                0% {
                    top: 0%;
                    opacity: 1;
                }
                100% {
                    top: 60%;
                    opacity: 0;
                }
            }
    }

    .HomePage-intro{
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: 2;

        .HomePage-intro-title{
            width: fit-content;
            font-size: 100px;
            font-family: 'Monoton', cursive;
            margin-bottom: 3%;
            padding-top: 25vh;
            padding-left: 10%;

            .HomePage-intro-title-surname{
                margin-bottom: 5%;
            }
        }

        .HomePage-intro-title-icon{
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 2vh;
            margin-left: 10%;
            
            a{
                cursor: pointer;
                font-size: 45px;
                color:var(--theme-first-color);
                margin-right: 10%;
            }

            #Kaggle{
                padding-right: 0;
                font-family: 'Unbounded', cursive;
                font-size: 30px;
            }
        }

        .HomePage-intro-title-icon a:hover{
            color:var(--theme-second-color);
        }

        .HomePage-intro-text{
            font-size: 25px;
            line-height: 60px;
            padding-left: 10%;
            padding-right: 10%;
            width: 50%;
        }
    }
}

.ProjectPage{
    width: 100%;
    height: fit-content;
    min-width: 300px;
    color: var(--theme-first-color);
    background-color: var(--theme-background-color);
    transition: background-color 0.8s;

    .ProjectPage-internship{
        width: 100%;
        height: fit-content;

        .ProjectPage-internship-text{
            font-size: 50px;
            padding-left: 10%;
            padding-top: 5vh;
        }

        .ProjectPage-internship-box{
            height: fit-content;
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .Internship-box{
                margin-top: 7vh;
            }

            .Experience-box{
                margin-top: 7vh;
            }
        }
    }

    .ProjectPage-personal{
        width: 100%;
        height: fit-content;

        .ProjectPage-personal-text{
            font-size: 50px;
            padding-left: 10%;
            padding-right: 10%;
            width: 80%;
            padding-top: 7vh;
        }

        .ProjectPage-personal-box{
            height: fit-content;
            width: 80%;
            padding-left: 10%;
            padding-right: 10%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .Personal-box{
                margin-top: 7vh;
            }
        }
    }

    .ProjectPage-contact{
        color: var(--theme-first-color);
        background-color: var(--theme-background-color);
        transition: background-color 0.8s;
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;

        .ProjectPage-contact-question{
            width: 80%;
            padding-left: 10%;
            padding-right: 10%;
            padding-top: 10vh;
            font-size: 20px;
            text-align: center;
        }

        .ProjectPage-contact-text{
            width: 80%;
            padding-left: 10%;
            padding-right: 10%;
            padding-top: 10vh;
            padding-bottom: 8vh;
            text-align: center;

            a{
                color: var(--theme-first-color);
                width: fit-content;
                height: fit-content;
                font-size: 35px;
                word-spacing: 10px;
                transition: font-size 0.3s ease;
            }
            
            a:hover{
                color:var(--theme-second-color);
            }
        }
    }
}

/*========================== RESPONSIVE ==========================*/
@media screen and (min-width: 2500px){
    .HomePage{

        .HomePage-intro{

            .HomePage-intro-title{
                font-size: 100px;
            }

            .HomePage-intro-title-icon{

                a{
                    font-size: 65px;
                }
                
                #Kaggle{
                    font-size: 45px;
                }
            }

            .HomePage-intro-text{
                font-size: 35px;
                line-height: 60px;
            }
        }
    }
}

@media screen and (max-width: 1500px) and (max-height: 900px) {
    .HomePage{

        .HomePage-background{

            .HomePage-background-picture{
                margin-left: 25%;
            }
        }
    
        .HomePage-intro{
            width: 80%;

            .HomePage-intro-title{
                font-size: 65px;
            }

            .HomePage-intro-title-icon{

                a{
                    font-size: 35px;
                }

                #Kaggle{
                    font-size: 25px;
                }
            }

            .HomePage-intro-text{
                font-size: 19px;
                line-height: 40px;
            }
        }
    }
}

@media screen and (max-width: 650px), (max-height: 700px) {

    .HomePage{

        .HomePage-background{
            opacity: 0.6;

            .HomePage-background-picture{
                margin-left: 0;
                width: 100%;
            }
        }

        .HomePage-mouse-loader{
            display: none;
        }

        .HomePage-intro{
            width: 90%;

            .HomePage-intro-title{
                font-size: 40px;
                padding-top: 30vh;
            }

            .HomePage-intro-title-icon{

                a{
                    font-size: 35px;
                }

                #Kaggle{
                    font-size: 25px;
                }
            }

            .HomePage-intro-text{
                font-size: 15px;
                line-height: 2;
                padding-top: 5vh;
                width: 90%;
            }
        }
    }

    .ProjectPage{

        .ProjectPage-internship{

            .ProjectPage-internship-text{
                font-size: 25px;
            }
        }

        .ProjectPage-personal{

            .ProjectPage-personal-text{
                font-size: 25px;
            }

        }

        .ProjectPage-contact{

            .ProjectPage-contact-question{
                font-size: 15px;
            }

            .ProjectPage-contact-text{
                padding-top: 4vh;

                a{
                    font-size: 25px;
                }
                
            }
        }
    }
}

@media screen and (max-height: 450px) {

    .HomePage{

        .HomePage-background{

            .HomePage-background-picture{
                background-size: contain;
                background-position: center;
                width: 100%;
            }
        }
    }
}
</style>