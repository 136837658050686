<template>
    <div class="Footer">
        <div class="Footer-line"></div>
        <div class="Footer-content">
            <div class="Footer-content-left">2025 &mdash; <strong>Victor Billat</strong></div>
            <div class="Footer-content-right">
                <a id="Linkedin" href="https://linkedin.com/in/victor-billat"><i class="uil uil-linkedin"></i></a>
                <a id="Github" href="https://github.com/Vic06163"><i class="uil uil-github"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
import en from './en'
import fr from './fr'

export default {
  data() {
    return {
    }
  },

  name: 'InternshipBox',

  props: {
    english: Boolean,
  },
    
  mixins:[en, fr],

  methods: {
  translate(prop) {
      if(!this.english) {
        return this["fr"][prop]
      } else {
        return this["en"][prop]
      }
    }
  }
}
</script>

<style lang="scss">
@import url('../style/reset.css');

.Footer{
    width: 100%;
    height: 15vh;
    min-width: 300px;
    min-height: 70px;
    max-height: 150px;
    color: var(--theme-first-color);
    background-color: var(--theme-background-color);
    transition: background-color 0.8s;

    .Footer-line{
        width: 80%;
        height: 2%;
        margin-left: 10%;
        margin-right: 10%;
        background-color: var(--theme-first-color);
    }

    .Footer-content{
        width: 80%;
        height: 98%;
        padding-left: 10%;
        padding-right: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .Footer-content-right{
            display: flex;
            flex-direction: row;

            i{
                cursor: pointer;
                font-size: 45px;
                color:var(--theme-first-color);
            }

            i:hover{
                color:var(--theme-second-color);
            }

            #Linkedin{
                padding-right: 50px;
            }
        }
    }
}

/*========================== RESPONSIVE ==========================*/
@media screen and (max-width: 650px){

  .Footer{

    .Footer-content{
      font-size: 10px;

      .Footer-content-right{

        i{
          font-size: 35px;
        }

        #Linkedin{
          padding-right: 20px;
        }
      }
    }
  }
}
</style>