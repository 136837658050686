<template>
  <div class="Experience-box">
    <div class="Experience-box-background">
      <img loading="eager" :src="backgroundImage" alt="Experience background image">
    </div>
    <div class="Experience-box-on-going">
      <div class="Experience-box-on-going-text">{{ onGoingText }}</div>
    </div>
    <div class="Experience-box-content">
      <div class="Experience-box-content-top">
        <div class="Experience-box-content-top-information">
          <div class="Experience-box-content-top-information-date">{{ date }}</div>
          <div class="Experience-box-content-top-information-title">{{ title }}</div>
          <div class="Experience-box-content-top-information-text">{{ text }}</div>
        </div>
      </div>
      <div class="Experience-box-content-bottom">
        <div class="Experience-box-content-bottom-country">
          <i class="uil uil-map-pin-alt"></i>
          {{ country }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import en from './en'
import fr from './fr'

export default {
  data() {
    return {
    }
  },

  name: 'ExperienceOnGoing',

  props: {
    english: Boolean,
    date: String,
    country: String,
    title: String,
    text: String,
    onGoingText: String,
    backgroundImage: {
      type: String,
      required: true
    }
  },
    
  mixins:[en, fr],

  methods: {
  translate(prop) {
      if(!this.english) {
        return this["fr"][prop]
      } else {
        return this["en"][prop]
      }
    }
  }
}
</script>

<style lang="scss">
@import url('../style/reset.css');

.Experience-box{
    width: 500px;
    height: 70vh;
    min-height: 350px;
    position: relative;
    overflow: hidden;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1vh;

    .Experience-box-background{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        opacity: var(--theme-opacity);
        transition: transform 0.5s ease;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .Experience-box-on-going{
        width: 90%;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        padding: 5%;
        font-weight: bold;
        font-size: 20px;
        z-index: 2;

        .Experience-box-on-going-text{
          padding: 2%;
          color: var(--theme-selection-color);
          background-color: var(--theme-selection-background-color);
          border-radius: 10px; 
        }
    }

    .Experience-box-content{
        width: 90%;
        height: 100%;
        position: relative;
        z-index: 2;
        transition: background-color 0.3s;
        display: flex;
        flex-direction: column;

        .Experience-box-content-top{
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .Experience-box-content-top-information{
                width: 100%;
                height: 100%;
                padding-top: 25%;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                .Experience-box-content-top-information-date{
                    padding-left: 10%;
                    padding-right: 10%;
                    padding-bottom: 5%;
                    width: 80%;
                    font-size: 25px;
                    color:var(--theme-first-color);
                }

                .Experience-box-content-top-information-country{
                    padding-left: 10%;
                    padding-right: 10%;
                    padding-bottom: 10%;
                    width: 80%;
                    font-size: 20px;
                    color:var(--theme-first-color);
                }

                .Experience-box-content-top-information-title{
                    padding-left: 10%;
                    padding-right: 10%;
                    font-size: 32px;
                    font-weight: bold;
                    width: 80%;
                    color:var(--theme-first-color);
                }

                .Experience-box-content-top-information-text{
                    padding-left: 10%;
                    padding-top: 10%;
                    padding-right: 10%;
                    font-size: 38px;
                    width: 80%;
                    height: 100%;
                    color:var(--theme-first-color);
                    overflow: hidden;
                    word-wrap: break-word;
                }
            }
        }

        .Experience-box-content-bottom{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            font-size: 20px;
            font-style: italic;
            color:var(--theme-first-color);

            .Experience-box-content-bottom-country{
                width: 100%;
                padding-left: 10%;
                padding-bottom: 3vh;
                display: flex;
                flex-direction: row;
                align-items: center;

                i{
                    font-size: 25px;
                    padding-right: 5%;
                    color: var(--theme-first-color);
                }
              }
        } 
    }
}

/*========================== RESPONSIVE ==========================*/
@media screen and (max-width: 650px){

  .Experience-box{

    .Experience-box-on-going{
      font-size: 15px;
    }

    .Experience-box-content{

      .Experience-box-content-top{

        .Experience-box-content-top-information{

          .Experience-box-content-top-information-date{
            font-size: 18px;
          }

          .Experience-box-content-top-information-title{
            font-size: 25px;
          }

          .Experience-box-content-top-information-text{
            font-size: 30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px), (max-height: 600px) {

  .Experience-box{

    .Experience-box-on-going{
      padding: 6%;
      padding-top: 3%;
      
      .Experience-box-on-going-text{
        font-size: 12px;
        padding: 2%;
        border-radius: 5px;
      }
    }

    .Experience-box-content{

      .Experience-box-content-top{

        .Experience-box-content-top-information{
          padding-top: 15%;

          .Experience-box-content-top-information-date{
            font-size: 14px;
          }

          .Experience-box-content-top-information-title{
            font-size: 20px;
          }

          .Experience-box-content-top-left-text{
            font-size: 25px;
          }
        }
      }

      .Experience-box-content-bottom{

        .Experience-box-content-bottom-country{
          font-size: 15px;
        }
      }
    }
  }
}
</style>