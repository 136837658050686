export default {
    data() {
        return {
            fr: {
                // MENU
                ProjectsTitle: "Projets",
                AboutTitle: "A propos de moi",
                ContactTitle: "Contact",
                FrenchButton: "Français",
                EnglishButton: "Anglais",
                CloseMenu: "FERMER",
                LoadingText: "Chargement...",

                // HOME PAGE
                TextHomePage: "Etudiant en école d'ingénieur spécialité informatique. Je suis passionné par la data science !",

                // PROJECT PAGE
                MyInternshipText: "Mes expériences",
                ContactText: "N'hésitez pas à me contacter",

                // ABOUT PAGE
                HelloTitle: "Bonjour !\nJe m'appelle Victor",
                AboutTopText: "J'ai 22 ans et je viens de Bordeaux, en France.\n",
                ScrollText: "Faites défiler pour en apprendre plus",
                TitleTool: "Outils utilisés",
                TitleWeb: "Compétences développement web",
                TitleData: "Compétences en data science",
                TextPython: "Python (data, web scraping, IA)",
                TitleLearning: "Compétences que j'apprends",
                FormationTitle: "Formation",
                FormationText1: "Je suis actuellement en dernière année d'école d'ingénieur à l'école CESI en spécialité informatique à Bordeaux en France.",
                FormationText2: "PBL Method (Problem Based Learning), apprentissage par des projets concrets en groupe.",
                FormationText3: "Gestion de projet (Agile)",
                FormationText4: "Projets en machine learning, deep learning, bases de données, big data, développement web, ...",
                HobbiesTitle: "Centres d'intérêts",
                Trip: "Voyages",
                TripText: "Depuis mon enfance jusqu'à présent, j'ai eu la possibilité de voyager, ce qui a été une véritable chance pour moi. J'ai eu l'occasion de visiter plusieurs pays tels que les États-Unis, les Pays-Bas, l'Irlande, la Grèce, et bien d'autres.\n\nCe que j'apprécie particulièrement, c'est d'explorer les pays que je visite et de découvrir leur culture.\n\nÀ l'avenir, j'aimerais beaucoup voyager en Asie, car je n'ai jamais eu l'occasion de le faire.",
                Movie: "Cinéma",
                MovieText: "Le cinéma est bien plus qu'un simple divertissement pour moi, c'est une passion qui nourrit mon esprit et mon âme.\n\nChaque film que je regarde est une opportunité de grandir, d'apprendre et de ressentir. Il m'inspire, me motive et élargit mes horizons.\n",
                Sport: "Musculation",
                SportText: "Je fais de la musculation depuis maintenant 5 ans.\n\nLa musculation m'a permis d'améliorer ma confiance en moi. En développant ma force physique, j'ai développé une force mentale qui se traduit dans tous les aspects de ma vie.\n\nJe me sens plus sûr de moi, capable de relever n'importe quel défi qui se présente à moi.",

                // CONTACT PAGE
                TitlePage: "Entrons en contact !",
                ResumeText: "Vous pouvez télécharger mon CV",
                ResumeDownloadFR: "CV en français",
                ResumeDownloadEN: "CV en anglais",

                // INTERNSHIP PAGE
                TaskDone: "Missions réalisées",

                //ENEDIS PAGE
                DateEnedis: "Avril 2022 - Juillet 2022",
                LocationEnedis: "Bordeaux, France",
                TextEnedis: "Stage Data Analyst",
                EnedisTitle: "Développement de tableaux de bord",
                EnedisText: "Ce stage a été ma première expérience professionnel dans le domaine de la data et m'a donnée envie de continuer dans celui-ci.",
                EnedisTask1: "Elaboration de tableau de bord développés sous Power BI pour le CODIR de la Direction Régionale Enedis Aquitaine Nord ainsi que d'autres services.",
                EnedisTask2: "Construire un support de formation à Power BI.",

                //VINCI PAGE
                DateVinci: "Janvier 2023 - Avril 2023",
                LocationVinci: "Bordeaux, France",
                TextVinci: "Stage Data Scientist",
                VinciTitle: "Smartvia - REGINA : La route connectée au service de la maintenance des infrastructures routières",
                VinciText: "Entre 2015 et 2019, un contour autoroutier a été construit dans la ville de Regina, dans la capital de Saskatchewan au Canada.\nLe groupe VINCI a participé à ce chantier est à placé des capteurs dans la chaussée afin d'étudier la déformation de la route. Ce stage a pour objectif d'automatiser le traitement des données récoltées et de concevoir un tableau de bord pour les visualiser.",
                VinciTask1: "Création de scripts python permettant le traitement automatique de données de déformation de structure de chaussée.",
                VinciTask2: "Création d'une base de données SQL pour les données traitées.",
                VinciTask3: "Elaboration d'un tableau de bord permettant la consultation des données.",
                VinciTask4: "Initialisation à la mise en œuvre d'un modèle de prédiction utilisant des techniques d'intelligence artificielle.",

                // LEADIX PAGE
                DateLeadix: "Septembre 2023 - Janvier 2024",
                LocationLeadix: "Bruxelles, Belgique",
                TextLeadix: "Stage Développeur Backend Python",
                LeadixTitle: "Développement et maintenance d'un Saas",
                LeadixText: "J'ai rejoint Leadix en tant que développeur backend Python pour travailler sur un outil. Pendant le stage, j'ai implémenté et amélioré des fonctionnalités et j'ai également fait de la maintenance. L'objectif de ces missions était d'avoir un outil qui assiste un growth marketer en allégeant au maximum sa charge de travail notamment sur les tâches longues qui pourraient être automatisées. Cela permettrait à un growth marketer d'accorder plus de temps sur d'autres tâches.\n\nJ'ai découvert pour la première fois la Belgique et plus particulièrement Bruxelles. J'ai découvert une ville cosmopolite et un pays passionnant. Enfin, j'ai rencontré l'équipe de Leadix. Des personnes dont j'ai beaucoup appris et avec qui j'ai passé de bons moments.",
                LeadixTask1: "Maintenance d’un SaaS hébergé sur Microsoft Azure.",
                LeadixTask2: "Gestion CI/CD.",
                LeadixTask3: "Gestion du front du SaaS sur Bubble (no-code).",
                LeadixTask4: "Implémentation de nouvelles fonctionnalités utilisant notamment le web scraping et diverses API.",
                
                // STELLANTIS PAGE
                DateStellantis: "Septembre 2024 - aujourd'hui",
                LocationStellantis: "Bordeaux, France",
                TextStellantis: "Alternance Data Analyst & Data Scientist",
                OnGoingText: "En cours",
                
                // PERSONNAL PROJECT
                MyPersonalText: "Mes projets personnels",

                // PORTFOLIO PROJECT PAGE
                PortfolioTitleBox: "Site web personnel",
                PortfolioText: "Ayant envie d'apprendre le développement web, j'ai décidé de réaliser un portfolio personnel sur lequel vous êtes actuellement.\nJ'ai choisi le framework Vue.js car c'est un framework très populaire de nos jours mais aussi parce c'est une solution qui présente de nombreux avantages d'utilisation.\n\nCe portfolio n'est pas encore une version définitif. J'essaye constamment d'apprendre de nouvelles notions afin de l'améliorer et de monter en compétences donc restez connecté !",
            
                // YOUTUBE VIDEO DEEPFACE
                YoutubeVideoBox: "Vidéo - Les deepfakes dans la politique",
                YoutubeVideoText: "Les deepfakes sont des vidéos truquées qui peuvent être utilisées pour manipuler l'opinion publique.\n Dans cette vidéo, j'explore les événements politiques où les technologies liées aux deepfakes ont été utilisées comme outil, en bien ou en mal (spoiler : c'est surtout en mal).",
            }
        }
    }
}