<template>
    <div class="Contact">
      <div class="Contact-container">
        <div class="Contact-container-top">
          <div class="Contact-container-top-text" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="150">{{ translate("TitlePage") }}</div>
        </div>
        <div class="Contact-container-bottom">
          <div class="Contact-container-bottom-resume" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="250">
            {{ translate("ResumeText") }}
            <div class="Contact-container-bottom-resume-link">
              <a href="cv/CV_Victor_BILLAT.pdf" download>{{ translate("ResumeDownloadFR") }}</a>
              <a href="cv/Resume_Victor_BILLAT.pdf" download>{{ translate("ResumeDownloadEN") }}</a>
            </div>
          </div>
          <div class="Contact-container-bottom-information" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :delay="350">
            <div class="Contact-container-bottom-information-email">
              <i class="uil uil-envelope-alt"></i>
              vic.billat@gmail.com
            </div>
            <div class="Contact-container-bottom-information-phone">
              <i class="uil uil-phone"></i>
              +33 6 59 35 11 49
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
</template>

<script>
import en from '../components/en'
import fr from '../components/fr'
import Footer from '../components/Footer'


export default {
  data() {
    return {
      PlaceholderName: "",
      PlaceholderEmail: "",
      PlaceholderMessage: "",
    };
  },

  components:{
    Footer
  },

  props: {
    english: Boolean,
  },
  
  mixins:[en, fr],

  methods: {
  translate(prop) {
      if(!this.english) {
        return this["fr"][prop]
      } else {
        return this["en"][prop]
      }
    }
  }
}
</script>

<style lang="scss">
@import url('../style/reset.css');

.Contact{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-width: 300px;
  min-height: 600px;
  color: var(--theme-first-color);
  background-color: var(--theme-background-color);
  transition: background-color 0.8s;

  .Contact-container{
    width: 80%;
    padding-top: 15vh ;
    padding-left: 10%;
    padding-right: 10%;
    height: fit-content;

    .Contact-container-top{
      height: fit-content;
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      padding-bottom: 8vh;

      .Contact-container-top-text{
        word-spacing: 15px;
        font-size: 100px;
        font-family: 'Monoton', cursive;
      }
    }

    .Contact-container-bottom{
      height: fit-content;
      width: 100%;
      display: flex;
      flex-direction: column;

      .Contact-container-bottom-resume{
        height: fit-content;
        width: 100%;
        font-size: 18px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .Contact-container-bottom-resume-link{
          padding-top: 6vh;
          padding-bottom: 8vh;
          height: fit-content;
          width: 80%;
          display: flex;
          justify-content: space-around;

          a{
            width: 40%;
            height: fit-content;
            background-color: var(--theme-background-navbar-color);
            transition: background-color 0.8s;
            padding: 15px;
            font-size: 20px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            color: var(--theme-first-color);
            text-transform: uppercase;
            text-align: center;
          }

          a:hover{
            background-color:var(--theme-second-color);
            cursor: pointer;
          }
        }
      }

      .Contact-container-bottom-information{
        width: 100%;
        height: fit-content;
        font-size: 18px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;

        i{
          font-size: 40px;
          margin-right: 10%;
        }

        div{
          width: 35%;
          display: flex;
          align-items: center;
          justify-content: center
        }
      }
    }
  }
}

/*========================== RESPONSIVE ==========================*/
@media screen and (max-width: 1100px){

  .Contact{

    .Contact-container{

      .Contact-container-top{

        .Contact-container-top-text{
          font-size: 65px;
        }
      }

      .Contact-container-bottom{

        .Contact-container-bottom-resume{
          font-size: 15px;

          .Contact-container-bottom-resume-link{
            padding-top: 3vh;
            padding-bottom: 3vh;

            a{
              font-size: 15px;
            }
          }
        }

        .Contact-container-bottom-information{
          font-size: 15px;

          i{
            font-size: 30px;
          }

          div{
          width: 45%;
        }
        }
      }
    }
  }
}

@media screen and (max-width: 650px), (max-height: 600px){

  .Contact{

    .Contact-container{
      padding-top: 90px;

      .Contact-container-top{
        padding-bottom: 5vh;

        .Contact-container-top-text{
          font-size: 45px;
        }
      }

      .Contact-container-bottom{

        .Contact-container-bottom-resume{
          font-size: 15px;
          text-align: center;

          .Contact-container-bottom-resume-link{
            flex-direction: row;
            align-items: center;

            a{
              font-size: 12px;
              margin: 5%;
            }
          }
        }

        .Contact-container-bottom-information{
          flex-direction: column;
          font-size: 12px;

          i{
            font-size: 25px;
          }

          div{
            width: 80%;
            padding-top: 2vh;
            padding-bottom: 2vh;
          }
        }
      }
    }
  }
}
</style>