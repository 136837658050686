<template>
  <router-link class="Personal-box" :to="link">
    <div class="Personal-box-background">
      <img loading="eager" :src="backgroundImage" alt="Personal background image">
    </div>
    <div class="Personal-box-content">
      <div class="Personal-box-content-top">
        <div class="Personal-box-content-top-left">
          <div class="Personal-box-content-top-left-date">{{ date }}</div>
          <div class="Personal-box-content-top-left-text">{{ title }}</div>
        </div>
        <div class="Personal-box-content-top-right"></div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
    }
  },

  name: 'PersonalBox',

  props: {
    date: String,
    title: String,
    text: String,
    link: String,
    backgroundImage: {
      type: String,
      required: true
    }
  },
}
</script>

<style lang="scss">
@import url('../style/reset.css');

.Personal-box{
    width: 500px;
    height: 50vh;
    min-height: 300px;
    position: relative;
    overflow: hidden;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2vh;

    .Personal-box-background{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        opacity: var(--theme-opacity);
        transition: transform 0.5s ease;
        background-color: var(--theme-second-color);

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .Personal-box-content{
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        transition: background-color 0.3s;

        .Personal-box-content-top{
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .Personal-box-content-top-left{
                width: 80%;
                height: 100%;
                padding-top: 15%;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                word-wrap: break-word;

                .Personal-box-content-top-left-date{
                    padding-left: 10%;
                    padding-right: 10%;
                    padding-bottom: 10%;
                    width: 80%;
                    font-size: 25px;
                    color:var(--theme-first-color);
                }

                .Personal-box-content-top-left-text{
                    padding-left: 10%;
                    padding-right: 10%;
                    font-size: 38px;
                    width: 90%;
                    height: 100%;
                    color:var(--theme-first-color);
                    overflow: hidden;
                }
            }

            .Personal-box-content-top-right{
                width: 20%;
                height: 100%;
            }
        }
    }

    .Personal-box-content:hover{
        cursor: pointer;
        background-color: rgba(109, 199, 212, 0.5);
    }
}

.Personal-box:hover .Personal-box-background {
    transform: scale(1.1);
}

/*========================== RESPONSIVE ==========================*/
@media screen and (max-width: 650px){
  
  .Personal-box{

      .Personal-box-content{

          .Personal-box-content-top{

              .Personal-box-content-top-left{

                  .Personal-box-content-top-left-date{
                    font-size: 18px;
                  }

                  .Personal-box-content-top-left-text{
                    font-size: 30px;
                  }
              }
          }
      }
  }
}

@media screen and (max-width: 450px), (max-height: 600px){
  
  .Personal-box{

      .Personal-box-content{

          .Personal-box-content-top{

              .Personal-box-content-top-left{

                  .Personal-box-content-top-left-date{
                    font-size: 14px;
                  }

                  .Personal-box-content-top-left-text{
                    font-size: 25px;
                  }
              }
          }
      }
  }
}
</style>